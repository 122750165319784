$(".DeferredRecaptchaElement").parents("form").find("input").focus(function () {
    if (window.deferredRecaptchaProperties.GAPIscriptLoaded) return;
    getRecaptchaScript();
})

function getRecaptchaScript() {
    if (typeof grecaptcha != "undefined") return;
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = renderGoogleInvisibleRecaptcha;
        script.src = "https://www.google.com/recaptcha/api.js?onload=renderGoogleInvisibleRecaptcha&render=explicit&hl=" + window.deferredRecaptchaProperties.language;
        $("head:first").append(script);
}


window.renderGoogleInvisibleRecaptcha = function () {
  function onVerify($element) {
    return function ($element) {
      var $element = $element;
      return function (response) {
        if (!response || response.length == 0) {
          $element.addClass('ValidationFail');
        } else {
          $element.parents("form").find("input.RecaptchaTokenResponse").val(response);
          $element.removeClass('ValidationFail');
          $element.addClass('RecaptchaValidationPassed');
          $element.closest("form").find('.FormSubmitButton').removeAttr('disabled').click();
          $('.preference-center-profile #viewport-wide-spinner').show();
            //100px = offset of the sticky nav 
          $("html, body").animate({ scrollTop: $element.parents(".subscribe-block").offset().top - 100}, 0);
        }
      }
    }($element);
  };
  $(".DeferredRecaptchaElement").parents("form").find(".Form__Element.FormInvisibleRecaptcha").each(function (index, element) {
    var $element = $(element),
      $widgetContainer = $(".g-recaptcha", $element),
      siteKey = $element.data("veforms-sitekey");

    if ($widgetContainer.length == 1 && siteKey) {
      var widgetId = grecaptcha.render($widgetContainer[0], {
        sitekey: siteKey,
        size: 'invisible',
        badge: 'bottomright',
        callback: onVerify($element)
      });
      element.dataset.epiformsRecaptchaWidgetid = widgetId;
    }
  });
  $('.grecaptcha-badge').css('display', 'none');
}


/* recaptchaChacker
 * Code to listen for whether the recaptcha element is done with it's validation and then to manually trigger the resubmission of the form. Either on the main-subscription-registration-form or the au-registration form.
 */

window.recaptchaChecker = {

    triggered: false,
    initialised: false,
    classToCheck: "RecaptchaValidationPassed",
    elem: $('.main-subscription-registration-form .FormInvisibleRecaptcha, .au-registration .FormInvisibleRecaptcha')[0],
    observer: new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.attributeName == "class") {
                if (mutation.target.classList.contains(recaptchaChecker.classToCheck) && !recaptchaChecker.triggered) {
                    recaptchaChecker.classChangedTrigger();
                    recaptchaChecker.triggered = true;
                }
            }
        });
    }),
    classChangedTrigger: function () {
        $('.au-registration .FormSubmitButton,.main-subscription-registration-form .FormSubmitButton')[0].click();
        console.log("recaptchaChecker: Recaptcha passed, manually triggering form again.");
    },
    init: function () {
        if(recaptchaChecker.elem != undefined && !(recaptchaChecker.initialised)) {
            recaptchaChecker.observer.observe(recaptchaChecker.elem, {
                attributes: true
            })
        }
        recaptchaChecker.initialised = true;
    }

}

if (/complete|interactive|loaded/.test(document.readyState)) {
  recaptchaChecker.init();
} else {
  window.addEventListener("DOMContentLoaded", recaptchaChecker.init);
}