
function initVideoPlayer() {
    const players = document.querySelectorAll(".main-video-player"); 
    if (players.length === 0) return;

    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var player;
    window.onYouTubeIframeAPIReady = function () {
        var videoId = document.getElementById("main-player").dataset.src;
        player = new YT.Player('main-player', {
            height: '390',
            width: '640',
            videoId: videoId,
            playerVars: {
                'playsinline': 1
            },
            events: {
                'onReady': onPlayerReady,
            }
        });
    }

    function onPlayerReady(event) {
        // play icon for inline video elements
        let playIcons = document.querySelectorAll(".playIcon");
        playIcons.forEach(playIcon => {
            playIcon.addEventListener("click", function () {
                let parentCell = playIcon.closest(".cell");
                /* let videoEl = parentCell.querySelector("video");
                 videoEl.play();*/
                parentCell.classList.add("playing");
                event.target.playVideo();
            });
        })
    }      
}

export default initVideoPlayer
