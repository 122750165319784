//make jQuery accessible globally.
window.jQuery = require('jquery');
window.$ = jQuery;

import { Spinner } from 'spin.js';
window.Spinner = Spinner;

import { debounce } from "lodash";
require("regenerator-runtime/runtime");

require('generic-invisible-recaptcha-deferred.js');

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import zenScroll from "zenscroll";
import initVideoPlayer from "./video-player";

const body = document.querySelector("body");
const footer = document.querySelector("footer");

document.addEventListener("DOMContentLoaded", function () {

    // mobile menu btn
    let menuBtn = document.querySelector(".menuBtn");
    menuBtn.addEventListener("click", function(e) {
        e.preventDefault();
        body.classList.toggle("menuOpen");
    });

    //detect window.resize, check width, remove menuOpen for larger width
    window.addEventListener("resize", debounce(async () => {
        if (window.innerWidth > 1200) {
            body.classList.remove("menuOpen");
        }
    }, 300))

/*    // play icon for inline video elements
    let playIcons = document.querySelectorAll(".playIcon");
    playIcons.forEach(playIcon => {
        playIcon.addEventListener("click", function() {
            let parentCell = playIcon.closest(".cell");
            let videoEl = parentCell.querySelector("video");
            videoEl.play();
            parentCell.classList.add("playing");
        });
    })*/
    
    // PARALAX FIXED HERO ELEMENTS
    const foregroundWrap = document.querySelector(".foreground_wrap");
    const foregroundContent = document.querySelector(".foreground_wrap .content");
    const fixedHero = document.querySelector(".hero.fixed");
    if (typeof fixedHero != "undefined" && fixedHero != null) {

        gsap.to(fixedHero, {
            scrollTrigger: {
                trigger: foregroundWrap,
                start: 0,
                end: "bottom center",
                markers: false,
                scrub: true
            },
            y: "-20%"
        })
    }

    // PIN BRANCH ELEMENTS

    const leftBranch = document.querySelector("img.branch_left");
    const rightBranch = document.querySelector("img.branch_right");
    let branchTrigger;
    if (typeof leftBranch != "undefined" && leftBranch != null) {

        branchTrigger = foregroundContent;
        if (body.classList.contains("contactpage")) {
            branchTrigger = body;
        }
        
        // left branch
        gsap.set(leftBranch, {y: '100%'});        
        gsap.to(leftBranch, {
            scrollTrigger: {
                trigger: branchTrigger,
                start: "top top",
                end: "+=300",
                markers: false,
                scrub: true
            },
            y: 0
        })

        // right branch
        let st = ScrollTrigger.create({
            trigger: branchTrigger,
            pin: rightBranch,
            start: "top top",
            pinSpacing: false,
        });

    }

    // FADE OUT BRANCH ELEMENTS WHEN FOOTER ENTERS VIEWPORT
    gsap.to(leftBranch, {
        scrollTrigger: {
            trigger: footer,
            start: "top bottom",
            end: "bottom bottom",
            markers: false,
            scrub: true
        },
        alpha: 0
    })

    initVideoPlayer();

});